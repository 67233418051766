<template>
  <div>
    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div v-if="loading">
      <v-row justify="center" align="center">
        <v-col cols="12" md="12" class="text-center">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
            class="mt-2"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <v-form ref="form">
        <div v-if="data">
          <!-- <v-row v-if="data.unor">
            <v-col cols="12" md="8" class="mb-n6">
              <span class="subtitle-2">Unit Organisasi</span>
              <v-autocomplete
                v-model="data.unor.id"
                dense
                outlined
                readonly
                filled
                :items="refUnor"
                item-value="idUnor"
                item-text="nmUnor"
                class="mt-2"
              ></v-autocomplete>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col cols="12" md="8" class="mb-n6">
              <span class="subtitle-2">Nomor KTP</span>
              <v-text-field
                v-model="data.nik"
                dense
                outlined
                readonly
                filled
                class="mt-2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- KIRI -->
            <v-col cols="12" md="4">
              <v-row dense class="mb-n5">
                <v-col cols="12" md="6">
                  <span class="subtitle-2">NIP Baru</span>
                  <v-text-field
                    v-model="data.nipBaru"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <span class="subtitle-2">NIP Lama</span>
                  <v-text-field
                    v-model="data.nipLama"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense class="mb-n5">
                <v-col cols="12" md="12">
                  <span class="subtitle-2">Nama</span>
                  <v-text-field
                    v-model="data.nama"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense class="mb-n5">
                <v-col cols="12" md="6">
                  <span class="subtitle-2">Gelar Depan</span>
                  <v-text-field
                    v-model="data.gelarDepan"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <span class="subtitle-2">Gelar Belakang</span>
                  <v-text-field
                    v-model="data.gelarBelakang"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense class="mb-n5">
                <v-col cols="12" md="12" v-if="data.tempatLahir">
                  <span class="subtitle-2">Tempat Lahir</span>
                  <v-text-field
                    v-model="data.tempatLahir.nama"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense class="mb-n5">
                <v-col cols="12" md="6">
                  <span class="subtitle-2">Tanggal Lahir</span>
                  <v-text-field
                    v-model="data.tglLahir"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" v-if="data.jenisKelamin">
                  <span class="subtitle-2">Jenis Kelamin</span>
                  <v-text-field
                    v-model="data.jenisKelamin.nama"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense class="mb-n5">
                <v-col cols="12" md="12" v-if="data.agama">
                  <span class="subtitle-2">Agama</span>
                  <v-text-field
                    v-model="data.agama.nama"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense class="mb-n5">
                <v-col cols="12" md="6">
                  <span class="subtitle-2">Email Pribadi</span>
                  <v-text-field
                    v-model="data.email"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <span class="subtitle-2">Email Dinas</span>
                  <v-text-field
                    v-model="data.emailGov"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense class="mb-n5">
                <v-col cols="12" md="12">
                  <span class="subtitle-2">Nomor WhatsApp</span>
                  <v-text-field
                    v-model="data.noHp"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!-- KANAN -->
            <v-col cols="12" md="8">
              <v-row dense class="mb-n5">
                <v-col cols="12" md="12">
                  <span class="subtitle-2">Alamat</span>
                  <v-textarea
                    v-model="data.alamat.detail"
                    dense
                    flat
                    outlined
                    no-resize
                    readonly
                    filled
                    height="220"
                    class="mt-2"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row dense class="mb-n5">
                <v-col cols="12" md="4">
                  <span class="subtitle-2">Kecamatan</span>
                  <v-text-field
                    v-model="data.alamat.kec.nama"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <span class="subtitle-2">Desa</span>
                  <v-text-field
                    v-model="data.alamat.desa.nama"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <span class="subtitle-2">Dusun</span>
                  <v-text-field
                    v-model="data.alamat.dusun.nama"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense class="mb-n5">
                <v-col cols="12" md="6" v-if="data.jenisKawin">
                  <span class="subtitle-2">Status Kawin</span>
                  <v-text-field
                    v-model="data.jenisKawin.status"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <span class="subtitle-2">Suku</span>
                  <v-text-field
                    v-model="data.suku.nama"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                    :items="refSuku"
                    item-value="id"
                    item-text="nama"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense class="mb-n5">
                <v-col cols="12" md="12" v-if="data.jenisPegawai">
                  <span class="subtitle-2">Jenis Pegawai</span>
                  <v-text-field
                    v-model="data.jenisPegawai.nama"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense class="mb-n5">
                <v-col cols="12" md="6">
                  <span class="subtitle-2">Status Pegawai</span>
                  <v-text-field
                    v-model="data.statusASN"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" v-if="data.kedudukanHukum">
                  <span class="subtitle-2">Kedudukan Hukum ASN</span>
                  <v-text-field
                    v-model="data.kedudukanHukum.nama"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense class="mb-n5">
                <v-col cols="12" md="6">
                  <span class="subtitle-2">Status Data</span>
                  <v-text-field
                    v-model="data.data.status"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <span class="subtitle-2">Keterangan Data</span>
                  <v-text-field
                    v-model="data.statusData"
                    dense
                    flat
                    outlined
                    readonly
                    filled
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import refreshView from "@/store/asn/datautama/view";

export default {
  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        // console.log('Tutup Alert: ' + value)
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    session: "",
    loading: true,

    refKelamin: [
      { id: "M", nama: "Pria" },
      { id: "F", nama: "Wanita" },
    ],
    satusItem: ["active", "inactive"],
    refAgama: [],
    refKawin: [],
    refJenispegawaian: [],
    refkedudukanHukum: [],
    refLokasi: [],
    refUnor: [],
    refSuku: [],
    refKabupaten: {},
    refKecamatan: {},

    refKec: [],
    refDesa: [],
    refDusun: [],

    data: {},
  }),

  async mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;

      const url =
        process.env.VUE_APP_ASN + "datautama/nip/" + this.$route.params.id;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            refreshView.commit("refreshData", false);
            this.data = res.data.mapData.DataUtama;
            this.loading = false;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            fab
            text
            @click="openM()"
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Data Pribadi</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Edit Data Pribadi </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeM()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="ml-5 mr-5 mt-6">
        <v-form ref="form" v-if="data.unor">
          <div>
            <!-- <v-row v-if="data.unor">
              <v-col cols="12" md="8" class="mb-n6">
                <span class="subtitle-2">Unit Organisasi</span>
                <v-autocomplete
                  v-model="data.unor.id"
                  dense
                  outlined
                  :items="refUnor"
                  item-value="idUnor"
                  item-text="nmUnor"
                  class="mt-2"
                ></v-autocomplete>
              </v-col>
            </v-row> -->

            <v-row>
              <v-col cols="12" md="8" class="mb-n6">
                <span class="subtitle-2">Nomor KTP</span>
                <v-text-field
                  v-model="data.nik"
                  dense
                  outlined
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!-- KIRI -->
              <v-col cols="12" md="4">
                <v-row dense class="mb-n5">
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">NIP Baru</span>
                    <v-text-field
                      v-model="data.nipBaru"
                      dense
                      flat
                      outlined
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">NIP Lama</span>
                    <v-text-field
                      v-model="data.nipLama"
                      dense
                      flat
                      outlined
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="12">
                    <span class="subtitle-2">Nama</span>
                    <v-text-field
                      v-model="data.nama"
                      dense
                      flat
                      outlined
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Gelar Depan</span>
                    <v-text-field
                      v-model="data.gelarDepan"
                      dense
                      flat
                      outlined
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Gelar Belakang</span>
                    <v-text-field
                      v-model="data.gelarBelakang"
                      dense
                      flat
                      outlined
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="12" v-if="data.tempatLahir">
                    <span class="subtitle-2">Tempat Lahir</span>
                    <v-menu
                      v-model="menuTempatlahir"
                      :close-on-content-click="false"
                      :nudge-width="200"
                      offset-x
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="tempatLahir.nama"
                          dense
                          flat
                          outlined
                          class="mt-2"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                        ></v-text-field>
                      </template>

                      <v-card>
                        <v-form ref="pilihTL">
                          <v-list>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Pilih Tempat Lahir
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  Sesuaikan Dengan KTP
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>

                          <v-divider class="pa-n5"></v-divider>

                          <v-list>
                            <v-list-item class="mt-3">
                              <v-autocomplete
                                :items="refLokasi"
                                item-text="nama"
                                item-value="id"
                                label="Provinsi"
                                outlined
                                dense
                                @change="getKab"
                                return-object
                              ></v-autocomplete>
                            </v-list-item>
                            <v-list-item>
                              <v-autocomplete
                                v-model="kabupatenID"
                                :items="refKabupaten.sub"
                                item-text="nama"
                                item-value="id"
                                label="Kabupaten / Kota"
                                outlined
                                dense
                                @change="getKec"
                                return-object
                              ></v-autocomplete>
                            </v-list-item>
                            <v-list-item>
                              <v-autocomplete
                                v-model="kecamatanID"
                                :items="refKecamatan.sub"
                                item-text="nama"
                                item-value="id"
                                label="Kecamatan"
                                outlined
                                dense
                                @change="getKel"
                                return-object
                              ></v-autocomplete>
                            </v-list-item>
                          </v-list>

                          <v-card-actions class="pb-5 mt-n5">
                            <v-spacer></v-spacer>
                            <v-btn text @click="clearLokasi()"> Clear </v-btn>
                            <v-btn
                              color="primary"
                              text
                              @click="menuTempatlahir = false"
                            >
                              Simpan
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Tanggal Lahir</span>
                    <v-menu
                      ref="tglLahirPicker"
                      v-model="tglLahirPicker"
                      :close-on-content-click="false"
                      :return-value.sync="data.tglLahir"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.tglLahir"
                          dense
                          flat
                          outlined
                          readonly
                          class="mt-2"
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="data.tglLahir"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="tglLahirPicker = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.tglLahirPicker.save(data.tglLahir)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" v-if="data.jenisKelamin">
                    <span class="subtitle-2">Jenis Kelamin</span>
                    <v-select
                      v-model="data.jenisKelamin.id"
                      dense
                      flat
                      outlined
                      class="mt-2"
                      :items="refKelamin"
                      item-value="id"
                      item-text="nama"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="12" v-if="data.agama">
                    <span class="subtitle-2">Agama</span>
                    <v-select
                      v-model="data.agama.id"
                      dense
                      flat
                      outlined
                      :items="refAgama"
                      item-value="id"
                      item-text="nama"
                      class="mt-2"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Email Pribadi</span>
                    <v-text-field
                      v-model="data.email"
                      dense
                      flat
                      outlined
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Email Dinas</span>
                    <v-text-field
                      v-model="data.emailGov"
                      dense
                      flat
                      outlined
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="12">
                    <span class="subtitle-2">Nomor WhatsApp</span>
                    <v-text-field
                      v-model="data.noHp"
                      dense
                      flat
                      outlined
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <!-- KANAN -->
              <v-col cols="12" md="8">
                <v-row dense class="mb-n5">
                  <v-col cols="12" md="12">
                    <span class="subtitle-2">Alamat</span>
                    <v-textarea
                      v-model="data.alamat.detail"
                      dense
                      flat
                      outlined
                      no-resize
                      height="220"
                      class="mt-2"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="4">
                    <span class="subtitle-2">Kecamatan</span>
                    <v-select
                      v-model="data.alamat.kec.id"
                      dense
                      flat
                      outlined
                      class="mt-2"
                      :items="refKec"
                      item-value="id"
                      item-text="nama"
                      @change="Ckec"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <span class="subtitle-2">Desa</span>
                    <v-select
                      v-model="data.alamat.desa.id"
                      dense
                      flat
                      outlined
                      class="mt-2"
                      :items="refDesa"
                      item-value="id"
                      item-text="nama"
                      @change="Cdesa"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <span class="subtitle-2">Dusun</span>
                    <v-select
                      v-model="data.alamat.dusun.id"
                      dense
                      flat
                      outlined
                      class="mt-2"
                      :items="refDusun"
                      item-value="id"
                      item-text="nama"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="6" v-if="data.jenisKawin">
                    <span class="subtitle-2">Status Kawin</span>
                    <v-select
                      v-model="data.jenisKawin.id"
                      dense
                      flat
                      outlined
                      class="mt-2"
                      :items="refKawin"
                      item-value="id"
                      item-text="nama"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Suku</span>
                    <v-autocomplete
                      v-model="data.suku.id"
                      dense
                      flat
                      outlined
                      class="mt-2"
                      :items="refSuku"
                      item-value="id"
                      item-text="nama"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="12" v-if="data.jenisPegawai">
                    <span class="subtitle-2">Jenis Pegawai</span>
                    <v-select
                      v-model="data.jenisPegawai.id"
                      dense
                      flat
                      outlined
                      class="mt-2"
                      :items="refJenispegawaian"
                      item-value="id"
                      item-text="nama"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Status Pegawai</span>
                    <v-select
                      v-model="data.statusASN"
                      :items="refSP"
                      dense
                      flat
                      outlined
                      class="mt-2"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" v-if="data.kedudukanHukum">
                    <span class="subtitle-2">Kedudukan Hukum ASN</span>
                    <v-select
                      v-model="data.kedudukanHukum.id"
                      dense
                      flat
                      outlined
                      filled
                      readonly
                      class="mt-2"
                      :items="refkedudukanHukum"
                      item-value="id"
                      item-text="nama"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5" v-if="data.data">
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Status Data</span>
                    <v-select
                      v-model="data.data.status"
                      :items="satusItem"
                      dense
                      flat
                      outlined
                      class="mt-2"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Keterangan Data</span>
                    <v-select
                      v-model="data.statusData"
                      :items="refKet"
                      dense
                      flat
                      outlined
                      class="mt-2"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <hr class="my-3" />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn
              color="primary"
              depressed
              :loading="lbtn"
              :disabled="lbtn"
              @click="edit()"
            >
              SIMPAN
            </v-btn>
          </div>
        </v-form>

        <v-row justify="center" align="center" v-else>
          <v-col cols="12" md="12" class="text-center">
            <v-progress-linear
              color="primary"
              indeterminate
              rounded
              height="6"
              class="mt-2"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getReferensi.js";

import refreshView from "@/store/asn/datautama/view";

export default {
  computed: {
    kecamatan_id: function () {
      return this.data.alamat.kec.id;
    },

    desa_id: function () {
      return this.data.alamat.desa.id;
    },
  },

  watch: {
    async data(value) {
      this.refDesa = await getRef.Desa(this.token, value.alamat.kec.id);
      this.refDusun = await getRef.Dusun(
        this.token,
        value.alamat.kec.id,
        value.alamat.desa.id
      );
    },
  },

  data: () => ({
    token: Cookie.get("token"),

    ModalEdit: false,
    lbtn: false,

    menuTempatlahir: false,
    tglLahirPicker: false,

    tempatLahir: "",
    kabupatenID: "",
    kecamatanID: "",

    refKelamin: [
      { id: "M", nama: "Pria" },
      { id: "F", nama: "Wanita" },
    ],

    refSP: ["PNS", "PPPK"],
    refKet: ["lengkap", "belum lengkap", "tidak lengkap"],

    satusItem: ["active", "inactive"],
    refAgama: [],
    refKawin: [],
    refJenispegawaian: [],
    refkedudukanHukum: [],
    refLokasi: [],
    refUnor: [],
    refSuku: [],
    refKabupaten: {},
    refKecamatan: {},

    refKec: [],
    refDesa: [],
    refDusun: [],

    data: {},
  }),

  methods: {
    closeM() {
      this.ModalEdit = false;
    },

    async openM() {
      this.refUnor = await getRef.newUnor(this.token);
      this.refAgama = await getRef.Agama(this.token);
      this.refKawin = await getRef.Kawin(this.token);
      this.refJenispegawaian = await getRef.Jenispegawai(this.token);
      this.refkedudukanHukum = await getRef.kedudukanHukum(this.token);
      this.refLokasi = await getRef.Lokasi(this.token);
      this.refKec = await getRef.Kecamatan(this.token);
      this.refSuku = await getRef.Suku(this.token);

      this.getData();
      this.ModalEdit = true;
    },

    getData() {
      this.loading = true;

      const url =
        process.env.VUE_APP_ASN + "datautama/nip/" + this.$route.params.id;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.data = res.data.mapData.DataUtama;
            this.tempatLahir = res.data.mapData.DataUtama.tempatLahir;
            this.loading = false;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getKab(value) {
      this.refKabupaten = await getRef.subLokasi(this.token, value.id);
    },

    async getKec(value) {
      this.refKecamatan = await getRef.subLokasi(this.token, value.id);
      this.tempatLahir = value;
    },

    getKel(value) {
      this.tempatLahir = value;
    },

    clearLokasi() {
      this.$refs.pilihTL.reset();
      this.tempatLahir = "";
    },

    async edit() {
      this.lbtn = true;

      var insertTL;
      if (insertTL == undefined) {
        insertTL = this.tempatLahir.id;
      } else {
        if (this.kecamatanID != "") {
          insertTL = this.kecamatanID.id;
        } else {
          insertTL = this.kabupatenID.id;
        }
      }

      let data = {
        nipBaru: this.data.nipBaru,
        nipLama: this.data.nipLama,
        nama: this.data.nama,
        gelarDepan: this.data.gelarDepan,
        gelarBelakang: this.data.gelarBelakang,
        unor: {
          id: this.data.unor.id,
        },
        tempatLahir: {
          id: insertTL,
        },
        tglLahir: this.data.tglLahir,
        akteKelahiran: this.data.akteKelahiran,
        agama: {
          id: this.data.agama.id,
        },
        email: this.data.email,
        emailGov: this.data.emailGov,
        nik: this.data.nik,
        alamat: {
          kec: {
            id: this.data.alamat.kec.id,
          },
          desa: {
            id: this.data.alamat.desa.id,
          },
          dusun: {
            id: this.data.alamat.dusun.id,
          },
          detail: this.data.alamat.detail,
        },
        suku: this.data.suku.id,
        noHp: this.data.noHp,
        noTelp: this.data.noTelpon,
        jenisKelamin: {
          id: this.data.jenisKelamin.id,
        },
        jenisKawin: {
          id: this.data.jenisKawin.id,
        },
        jenisPegawai: {
          id: this.data.jenisPegawai.id,
        },
        kedudukanHukum: {
          id: this.data.kedudukanHukum.id,
        },
        statusASN: this.data.statusASN,
        statusData: this.data.statusData,
        data: {
          status: this.data.data.status,
          keterangan: this.data.data.keterangan,
        },
      };

      const url =
        process.env.VUE_APP_ASN + "datautama/nip/" + this.$route.params.id;

      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.lbtn = false;
          this.ModalEdit = false;
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.lbtn = false;
          this.ModalEdit = false;
        });
    },

    async Ckec(value) {
      this.refDesa = await getRef.Desa(this.token, value);
    },

    async Cdesa(value) {
      this.refDusun = await getRef.Dusun(
        this.token,
        this.data.alamat.kec.id,
        value
      );
    },
  },
};
</script>
